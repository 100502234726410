:root {
  --black--: #000;
  --white--: #fff;
  --prime--: #524ae3;
  --blackPrime--: #0f172a;
  --grayPrime--: #e6ebf1;
  --inputBorder--: #cbd5e1;
  --btnPrime--: #524ae3;
  ---sideBarColor--: #555b6d;
  --textColor--: #664dc9
}

* {
  font-family: "Nunito Sans", sans-serif;
}

/* ========================================= Login Page CSS ========================== */
.Login_page_logo {
  background-color: #eff2f7;
  border-radius: 50%;
  height: 70px;
  position: relative;
  position: absolute;
  top: -37px;
  width: 70px;
  border: 2px solid #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, .4);
}

.container {
  max-width: 1200px !important;
}

/* .mainLoginPage {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
} */

/* .loginDiv {
  border-radius: 16px;
  width: 440px;
  margin: auto;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  overflow: hidden;
}

.loginPage {
  background-color: var(--white--);
  padding: 40px;
}

.loginLogo {
  height: 100px;
  width: 100px;
  background-color: var(--white--);
  border-radius: 50%;
}

.logoBar {
  height: 140px;
  background-color: var(--blackPrime--);
  padding: 20px;
}

.forgetPassword {
  color: var(--prime--) !important;
} */


.loginBox-shadow {
  box-shadow: 0px 0px 17px #664dc963, 0px 0px 37px #68637770;
}

/* ========================================= Navbar Page CSS ========================== */

.navBar {
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, .03);
  padding: 5px 0px;
  font-size: 18px;
}

.mainNavbar {
  cursor: pointer;
  transition: 0.3s;
  z-index: 11;
}

/* Nav Menu Toggle JS CSS */
.webNav {
  margin-left: 220px;
}

.mobNav {
  margin-left: 75px;
}

.app-sidebar__toggle {
  border-right: 1px solid #e6ebf1;
  font-size: 1.2rem;
  padding: 0.85rem 1rem;
  position: relative;
  border-bottom: 1px solid #e6ebf1 !important;
  border-left: 1px solid #e6ebf1 !important;
}

/* Mobile Size */
@media screen and (max-width: 992px) {
  .webNav {
    margin-left: 0;
  }

  .mobNav {
    margin-left: 0;
    z-index: 111111;
  }
}

/* ========================================= Sidebar Page CSS ========================== */

/* Side Menu Toggle JS CSS */
.webSidebar {
  width: 220px;
}



.mobSidebar {
  width: 75px;
}

.sideBar.mobSidebar .sideBarLogo span {
  display: none;
}

.mobSidebar .mainMenu li a {
  font-size: 19px;
  color: #555b6d
}

.sideBar img {
  width: 72px;
  display: none;
  height: 40px;
}

.webSidebar .webLogo {
  display: block;
}

.mobSidebar .mobileLogo {
  display: block;
  width: 40px;
  height: auto;
}

.sideBar.mobSidebar .mainMenu li a>div>span,
.sideBar.mobSidebar .mainMenu>li>a>i,
.sideBar.mobSidebar .subMenu li a span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a {
  justify-content: center;
}

.sideBar.mobSidebar .mainMenu li a i {
  margin: 0;
  padding: 0 25px 0 17px;
}

.sideBar.mobSidebar .subMenu li a {
  padding-left: 22px;
}

/* End Side Menu Toggle JS CSS */

/* Mobile Size */
@media screen and (max-width: 992px) {
  .webSidebar {
    width: 75px;
  }

  .sideBar .sideBarLogo span {
    display: none;
  }

  .sideBar .mainMenu li a>div>span,
  .sideBar .mainMenu>li>a>i,
  .sideBar .subMenu li a span {
    display: none;
  }

  .sideBar .mainMenu li a {
    justify-content: center;
  }

  .sideBar .mainMenu li a i {
    margin: 0;
  }

  .sideBar .subMenu li a {
    padding-left: 20px;
  }

  /* ============ */

  .mobSidebar {
    width: 220px;
  }

  .webSidebar {
    width: 0px;
  }

  .sideBar.mobSidebar .sideBarLogo span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a>div>span,
  .sideBar.mobSidebar .mainMenu>li>a>i,
  .sideBar.mobSidebar .subMenu li a span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a>div {
    display: flex;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu>li>a {
    justify-content: space-between;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu li a i {
    margin-right: 10px;
  }

  .sideBar.mobSidebar .subMenu li a {
    padding-left: 30px;
  }
}

.rotate90 {
  transform: rotate(90deg);
  transition: 0.3s;
}


.mainSidebar {
  position: fixed;
  top: 0;
  z-index: 11;
}

.sideBar {
  height: 100vh;
  background-color: #fff !important;
  color: var(--prime--) !important;
  transition: 0.3s;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .1) !important;
}

.sideBarLogo {
  border-bottom: 0.2px solid var(--grayPrime--);
  padding: 19px 11px;
}

.navigation {

  padding: 15px 10px;
  max-height: 93%;
  overflow: scroll;
}

.mainMenu>li {
  padding-bottom: 5px;
}

.mainMenu li a {
  border-radius: 0.25rem;
  color: var(--black--);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 7px 3px 7px 10px;
}



.mobSidebar .mainMenu li a {
  margin-left: 4px;
  margin-right: 0;
}

.mainMenu li>a:hover,
.mainMenu .activeMenu {
  background: #f3f6f8;
  color: #664dc9 !important;
  text-decoration: none;
}

.mainMenu li a span {
  font-size: 14px;
  font-weight: 500;
  height: auto;
  letter-spacing: .6px;
}

.webSidebar .mainMenu li a {
  margin-left: 5px;
  margin-right: 3px;
  padding: 7px 14px 7px 10px;
  color: var(---sideBarColor--);
  font-size: 18px;
}

.mainMenu li a i {
  margin-right: 10px;
  transition: 0.3s;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

/* 
.content.open {
  max-height: 100px;
} */

/* .transform0 {
  transform: scaleY(0);
  transition: height 0.3s ease;
  transform-origin: top;
  height: 0;
}

.transform1 {
  transform: scaleY(1);
  transition: height 0.3s ease;
  transform-origin: top;
  height: auto;
} */


.subMenu {
  overflow: hidden;
}

.subMenu li a {
  justify-content: start;
  padding-left: 30px;
}

.subMenu li a i {
  font-size: 6px;
}


/* feedback  */

.feedback__title,
.redeem__title {
  font-weight: 750;
  font-size: 16px;
}

.feedback__details,
.redeem__details {
  font-size: 15px;
}

/* ================================== Admin CSS =================================== */

.mainAdmin {
  padding: 10px;
  margin-left: 218px;
  margin-top: 56px;
  margin-right: 0px;
  height: 91vh;
  background-color: #f8f8fc;
  transition: 0.3s;

}

.mobAdmin {
  margin-left: 85px;
}

/* Mobile Size */
@media screen and (max-width: 992px) {
  .mainAdmin {
    position: relative;
    z-index: 1;
    margin-left: 0;
  }
}

/* Navbar CSS */
.navIcons i {
  color: #5c678f;
  padding-right: 12px;
  padding-left: 12px;
  /* border-right: 1px solid #e6ebf1; */

}

.navIcons .MuiAvatar-root img {
  width: 32px;
  height: 32px;
}


/*  ====== user css */

.user-button i {
  margin-right: 5px;
}

.user-button .btn {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-button .btn {
  color: #fff !important;
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: .81rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 0.375rem 1em;
  text-align: center;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.user-button {
  display: grid;
  gap: 14px;
  grid-template-columns: repeat(6, minmax(0, 1fr));

}

.user-button .btn:focus {
  box-shadow: 0 0 0 2px rgba(112, 94, 200, .1);
}

.user-button .btn-new {
  background-color: var(--blueLight--) !important;
  border-color: var(--blueLight--);
}

.user-button .btn-new:focus {
  border-color: var(--blueLight--) !important;
  background-color: var(--blueLight--) !important;

}

.user-button .btn-refresh {
  background-color: #ef4b4b !important;
}

.user-button.btn-refresh:focus {
  border-color: #ef4b4b;
}

.user-button .btn-resetAll {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-resetAll:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, .1);
}

.btn-resetAll:focus-visible {
  background-color: #23272b !important;
  border-color: #1d2124 !important;
  color: #fff !important;
}


.user-button .form-select option span {
  font-weight: 400;
  line-height: 1.428571429;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;

}

.user-button .form-select option span:hover {
  background-color: #f0f0f2 !important;
}

.user-button .form-select:focus {
  box-shadow: 0 0 0 2px rgba(56, 203, 137, .1);
}

.user-button .gender-select {
  background-color: #42c48a;
  border-color: #42c48a;
}

.user-button .gender-select:focus {
  background-color: #42c48a;
  border-color: #42c48a;
}

.user-button .status-select {
  background-color: #3e80eb;
  border-color: #3e80eb;
}

.user-button .status-select:focus {
  background-color: #3e80eb;
  border-color: #3e80eb;
}

.user-button .blocked-select {
  background-color: #feb521;
  border-color: #feb521;
}

.user-button .blocked-select:focus {
  background-color: #feb521;
  border-color: #feb521;
}

.user-button .country-select {
  background-color: #38cb89;
  border-color: #38cb89;
}

.user-body {
  margin: 24px;
}

.user-button .country-select:focus {
  background-color: #38cb89;
  border-color: #38cb89;
}

.user-button select::-ms-expand {
  display: none;
}

/* host Info  start*/

.profileImage {
  border-width: 5px !important;
  height: 190px;
  width: 190px;
}

.styles-module_Editext__input__2-M50 {
  width: 50% !important;
  height: 40px !important;
  border-radius: 5px;
}

.borderCT {
  border-left: 1px solid #e6ebf1;
}

.uTable tr:not(:last-child) {
  border-bottom: 1px solid #e6ebf1;
}

.uTable tr td:first-child {
  color: #664dc9;
}

.uTable td {
  padding: 11px 0px !important;
  font-size: 16px;
  font-weight: bold;
}

.styles-module_Editext__button__sxYQX {
  background-color: #eaeaea;
  border-radius: 3px !important;
  border-style: none !important;
  border-width: 0 !important;
  font-size: 12px !important;
  margin-left: 4px;
  max-width: 0 !important;
  padding: 0 !important;
}


.allDetails,
.imageCard {
  border-radius: 10px;
}

/* report  */

.accordion-button::after {
  display: none;
}


.accordion-button:not(.collapsed),
.accordion-button:focus {
  border-color: #bcc0c569 !important;
  background-color: #bcc0c569 !important;

}

.accordion-button:focus {
  box-shadow:none !important;
}

.accordion-body {
  background-color: #bcc0c530 !important;

}


/* host  info end  */



.settlementTable td,
.settlementTable th,
.text-wrap .settlementTable td,
.text-wrap .settlementTable th {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}


/* settlement css */



@media screen and (min-device-width: 1024px) and (max-device-width: 1244px) {
  .user-button {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1244px) {
  .user-button {

    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-items: center;
    align-items: flex-end;
    align-items: center;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .user-body-row {
    flex-direction: column;
    padding-right: 10px !important;
    align-items: flex-end;
  }

  .user-body-col {
    width: 100% !important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .user-button {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 10px;
  }

  .search-input {
    margin-bottom: 10px;
  }

  .user-body {
    margin: 6px;
  }
}