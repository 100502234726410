@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
/* font-family: 'Inter', sans-serif; */

:root {
    --black--: #000;
    --white--: #fff;
    --prime--: #524AE3;
    --blackPrime--: #0F172A;
    --grayPrime--: #cfcfcf;
    --inputBorder--: #cbd5e1;
    --btnPrime--: #524AE3;
    --textColor--: #664dc9;
    ---sideBarColor--: #555b6d;
    --primary--: #664dc9;
    ---secondary--: #3e80eb;
    --info--: #3e80eb;
    --success--: #38cb89;
    --btnColorDanger--: #ef4b4b;
    --warning--: #ffab00
}


/* Start Default CSS */

a {
    text-decoration: none;
}

li {
    list-style: none;
}

ul {
    margin-bottom: 0;
    padding-left: 0;
}


/* End Default CSS */


/* Start Theme Text Color */
.primeColor {
    color: var(--prime--) !important;
}

.primeBlackColor {
    color: var(--blackPrime--) !important;
}

/* End Theme Text Color */

/* Default Custom Dynamic CSS */

.themeFont {
    font-size: 14px;
    font-weight: 500;
    color: var(--blackPrime--);
}

.boxCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.breadcrumb1 {
    padding-top: 10px;
    border-radius: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0 !important;
    padding: 15px 1rem;
    font-size: 16px;
    font-weight: 700;
    color: var(--textColor--)
}

.breadcrumb-item1+.breadcrumb-item1:before {
    content: "/";
    display: inline-block;
    opacity: 0.5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.breadcrumb-item1+.breadcrumb-item1:hover:before {
    text-decoration: underline;
    text-decoration: none;
}



.activeItem {
    color: #6c756e;
}

.title-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.planMain {
    background-color: var(--white--);
    margin: 0 10px
}

thead {
    color: var(---sideBarColor--);
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px 4px 8px #00000015
}

.bg-secondary,
.btn-secondary {
    background-color: var(---secondary--) !important;
}


.bg-primary {
    background-color: var(--primary--) !important;

}

.bg-info,
.btn-info {
    background-color: var(--info--) !important;
}

.bg-success,
.btn-success {
    border: none;
    background-color: var(--success--) !important;
}

.badge-warning {
    border: none !important;
    color: #ff9104 !important;
    background-color: #fffcc7 !important;

}

.badge-secondary {
    border: none !important;
    color: #556eef !important;
    background-color: #e4f1ff !important;
}

.badge-success {
    border: none !important;
    color: #56c09f !important;
    background-color: #d0f4e7;
}

.badge-danger {
    background-color: #ffe1e7;
    color: #d46279 !important;
    border: none !important;
}

.bg-danger,
.btn-danger {
    border: none;
    background-color: var(--btnColorDanger--) !important;
}

.btn-warning,
.bg-warning {
    background-color: var(--warning--);
}

button {
    cursor: pointer;
}

.disabled-btn {
    border: 1px solid rgb(225, 18, 18) !important;
    font-size: 12px !important;
    background-color: #f1a5a5 !important;
}

.table-image {
    border-radius: 12px;
    display: block;
    height: 60px;
    object-fit: cover;
    width: 60px;
}

.deg90 {
    color: #9d9d9d;
    cursor: pointer;
    rotate: 90deg;
}

.dropdown-menu.show {
    display: block;
    margin: 0;
    max-height: 400px;
    overflow: auto;
}

.dropdown-menu>li>a {
    clear: both;
    color: #333;
    display: block;
    font-weight: 400;
    line-height: 1.428571429;
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    background-color: #f0f0f2;
}

.btn-primary {
    border: none !important;
    background-color: var(--primary--);
}

.btn-primary:hover {
    /* border: none !important; */
    background-color: var(--primary--) !important;
}

.btn-danger {
    background-color: var(--btnColorDanger--);
}

.text-primary {

    color: var(--primary--) !important
}

.border-primary {
    border-color: var(--primary--) !important;
}

.MuiRadio-colorSecondary.Mui-checked,
.MuiSwitch-colorPrimary.Mui-checked {
    color: var(--primary--) !important
}

.text-dark {
    color: var(--black--) !important;
}

.text-success {
    color: var(--success--) !important
}

.text-danger {
    color: var(--btnColorDanger--) !important
}

.MuiAvatar-img {
    border-radius: 50%;
}


th,
td {
    vertical-align: middle !important;
}

.accordian-body {
    max-height: 300px !important;
}

.mh-300 {
    max-height: 300px !important;
}

.mh-500 {
    max-height: 500px !important;
}

.mh-700 {
    max-height: 700px !important;
}

.bg-dark1 {
    background-color: #dfdfdf !important;
}

.bg-dark2 {
    background-color: #ebebeb !important;
}

.bg-dark3 {
    background-color: #f3f3f3 !important;
}

.bg-dark4 {
    background-color: #f7f7f7 !important;
}

.border-white {
    border-color: #fff !important;
}

.text-info {
    color: #3e80eb !important
}

.cursorPointer {
  cursor: pointer;
}

::selection {
    background-color: #664dc9;
    color: #fff;
}