@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&amp;display=swap");
/* font-family: 'Poppins', sans-serif;
font-family: 'Roboto Slab', serif; */

body {
    background: #f1f5f9;
    color: #495046;
    font-family: Nunito Sans, sans-serif !important;
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    overflow-x: hidden;
    text-align: left;
}

:root {
    --black--: #000;



    
    --white--: #fff;
    --prime--: #524AE3;
    --blackPrime--: #0F172A;
    --grayPrime--: #e6ebf1;
    --inputBorder--: #cbd5e1;
    --btnPrime--: #524AE3;
    --tablePrime--: #F8FAFC;
    --tablePrime--: #fefdff;
    --scrollColor--: #b6b6b6;
    --headerPrime--: #f0f4f8;
    --fontNuitosans--: "Nunito Sans, sans-serif";
    --fontRoboto--: "Roboto Slab", serif;
    --TableColumnBold--: #334155;
    --TableFont--: #64748b;
    --blueLight--: #664dc9;

}




/* ======================== Start Input CSS ======================== */

/* all Fields css */

.prime-input {
    margin-bottom: 15px;
    position: relative;
}

.form-input {
    width: 100% !important;
    height: 48px !important;
    border: 1px solid var(--inputBorder--) !important;
    border-radius: 6px;
    padding: 16px;
    font-size: 14px;
    cursor: pointer;
}


.form-input:focus {
    border: 1px solid var(--blackPrime--) !important;
    outline: none;
    background-color: #fefdff !important;
}

.prime-input label,
label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold !important;
    text-transform: capitalize !important;
    color: var(--blackPrime--);
}



/* For Input Type Password */

.passHideShow {
    position: absolute;
    top: 40px;
    right: 12px;
    cursor: pointer;
}

/* For Input Type Search */

.searching {
    position: absolute;
    top: 14px;
    right: 12px;
    cursor: pointer;
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    font-size: 16px;
}


/* For Input Type Number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Type Radio And Check Box */

.prime-input.radio,
.prime-input.checkbox {
    display: flex;
    align-items: center;
}

.prime-input.radio label,
.prime-input.checkbox label {
    margin-bottom: 0;
    margin-right: 10px;
    cursor: pointer;
}

.form-input[type=radio],
.form-input[type=checkbox] {
    height: 18px !important;
    width: 18px !important;
}

.form-input[type=radio],
.form-input[type=checkbox] {
    accent-color: var(--blackPrime--);
}

/* For Input Type File */

.form-input[type=file]::file-selector-button {

    margin-right: 10px;
    border: none;
    background: var(--blackPrime--);
    padding: 0px 20px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    height: 47px;
}

.form-input[type=file] {
    padding: 0px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.errorMessage {
    font-size: 14px;
    color: red;
}

/* ======================== End Input CSS ======================== */


/* ======================== Start Button CSS ======================== */


.themeBtn {
    border: 0;
    padding: 10px;
}

/* Button Background Color CSS */

.btnPrime {
    background-color: #664dc9;
    color: var(--white--);
}

.btnBlackPrime {
    background-color: #664dc9;

    color: var(--white--);
}




/* ======================== End Button CSS ======================== */

/* ======================== Start Toggle Switch CSS ======================== */

.checkbox {
    display: none;
}

.slider {
    width: 60px;
    height: 30px;
    background-color: lightgray;
    border-radius: 20px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 4px solid transparent;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    cursor: pointer;
}

.slider::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
    border-radius: 20px;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked~.slider::before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    -webkit-box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked~.slider {
    background-color: #2196F3;
}

.checkbox:active~.slider::before {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}

/* ======================== End Toggle Switch CSS ======================== */


/* ======================== Start Loader CSS ======================== */

.mainLoaderBox {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000070;
    width: 100%;
    height: 100%;
    z-index: 111111;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #fff #fff transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent var(--blackPrime--) var(--blackPrime--);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}

.loader::before {
    width: 32px;
    height: 32px;
    border-color: var(--prime--) var(--prime--) transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}


/* ======================== End Loader CSS ======================== */



/* ======================== Start Table CSS ======================== */

.primeHeader {

    padding: 10px 30px;

}


.titlePath {
    font-weight: 500;
}


.tableHeader {
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableMain {
    background-color: white;
    border-radius: 50px !important;
    margin: 0px 18px;
}

.primeMain {
    overflow: auto;
    max-height: 66vh;
}

.primeMainHistory {
    overflow: auto;
    max-height: 52vh;
}

.callReviewTable {
    position: sticky;
    border-spacing: 0px;
    top: 0;
    z-index: 1;
}

.callReviewTableShow {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: #fff;
}

.primeTable tr:hover {
    background-color: #f1f1f380;
}

.primeTable tr th {
    padding: 0px 2px 15px 2px;
    font-size: 16px;
    border-bottom: 1px solid #e6ebf1;
    color: #384b6e;
    font-size: 14px;

}

.primeTable tr td {
    padding: 15px 6px;
    font-size: 16px;
    border-bottom: 1px solid #e6ebf1;
    color: #384b6e;
    font-size: .81rem;
}

.primeTable .table-head-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.primeTable .table-head-text i {
    margin-right: 10px;
}






.tableBoldFont {
    color: var(--TableColumnBold--);
    /* font-size: 14px;  */
    font-weight: 500;
}


/* pagination CSS */
.paginationFooter {
    position: sticky;
    bottom: 0;
}

.pagination {
    width: 100%;
    background-color: var(--white--);
    padding: 14px 20px;
    /* box-shadow: 0 -3px 5px #00000020; */
}

.paginationBtn:disabled {
    opacity: 0.8;
}

/* ================================================Pagination CSS=========================================== */
.pagination {
    /* border-top: 1px solid var(--scrollColor--); */
    display: flex;
    justify-content: end;
    align-items: center;
}

.pageOption {
    padding: 10px 15px;
    border-radius: 15%;

}

.count {
    margin: 15px;
}

.page-btn {
    height: 40px;
    width: 40px;
    margin: 5px;
    /* padding: 8px 17px; */
    border-radius: 50%;
    background-color: #e2e8f0;
    color: #000;
    border: none;
    opacity: 0.5;
}

.active {
    height: 40px;
    width: 40px;
    margin: 5px;
    /* padding: 10px 17px; */
    border-radius: 50%;
    background-color: #664dc9;
    color: #fff;
    border: none;
}

.active-btn {
    height: 50px;
    width: 50px;
    margin: 5px;
    /* padding: 10px 15px; */
    border-radius: 50%;
    /* background-color: #494594; */
    color: #000;
    border: none;
}

.pagination .makeStyles-root-29 {
    display: flex !important;
}

/* Pagination Selection */

.tableSelection {
    margin-right: 15px;
    padding: 5px;
}

/* Pagination Selection */

.tableSelection {
    margin-right: 15px;
    padding: 5px;
}

/* Dialogue CSS */

.mainDialogue {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 111;
    top: 0;
    left: 0;
    background-color: #00000050;
    opacity: 0;
    animation: fade-in 0.1s ease-in;
    z-index: 99999;

}

.fade-in {
    opacity: 1;
    transition: opacity 0.1s ease-in;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.Dialogue {
    width: 600px;
    min-height: 290px;
    background-color: var(--white--);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 111;
    border-radius: 10px;
    max-height: 80%;
    overflow-y: scroll;
}

@media screen and (max-width:630px) {
    .Dialogue {
        width: 97%;
    }
}

/* Header */
.dialogueHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    padding: 20px;
    color: #664dc9;
}

.headerTitle {
    font-size: 23px;
}

.closeBtn {
    background-color: var(--blueLight--);
    color: var(--white--);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
}

/* Main */
.dialogueMain {
    padding: 20px;
    min-height: 100px;
    max-height: 622px;
    overflow-y: scroll;
}

/* Footer */
.dialogueFooter {
    padding: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
}

/* ======================== End Table CSS ======================== */


/* ======================== Start Scroll CSS ======================== */

::-webkit-scrollbar {
    height: 2px;
    width: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
    transition: all 5s ease-in-out;
    background: var(--scrollColor--);
}

::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollColor--);
    border-radius: 5px;
}


/* ======================== End Scroll CSS ======================== */


/* ======================== Start Setting Box CSS ======================== */

.settingMain {
    padding: 20px;

}

.settingBox {
    background-color: var(--white--);
    padding: 15px;
    border-radius: 10px;

}

.settingInnerBox .prime-input {
    margin-bottom: 5px !important;
}


.settingBoxHeader {
    margin-bottom: 15px;
}

.mainTitle {
    font-size: 13px;
    font-weight: bold;
}



/* ======================== End Setting Box CSS ======================== */


/* ======================== Start Dashboard Box CSS ======================== */
.allTxt {
    position: relative;
    z-index: 111;
    padding: 25px;
}

.dashboard-page {
    padding: 24px;
}

.page-header {
    align-items: center;
    display: flex;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding: 0;
    position: relative;
}

.dash1-card {
    position: relative;
}

.dash1-card p {
    font-size: 14px;
}

.dash1-card .ratio {
    background-color: #222;
    border-radius: 15px 0 0 15px;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    padding: 5px 15px;
    position: absolute;
    right: -10px;
    top: 20px;
}

.dash1-card .ratio-text {
    font-size: 13px;
    position: absolute;
    right: 5px;
    top: 55px;
}

.dashIcon {
    width: 75px;
    height: 75px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #fff; */
    font-size: 32px;
}

.dashIcon-prime {
    background-color: #dfd9f7;
    color: var(--blueLight--);
}

.dashIcon-dangur {
    background-color: #fdd4d4;
}

.dashIcon-success {
    background-color: #b9eebc;
}

.dashIcon-warning {
    background-color: #f7edd9;
}

.carn1 {
    color: var(--blueLight--) !important;
}

.dashIcon-danger {
    background-color: #ffc9c9;
}

.dashMain .card-body {
    position: relative;
    padding: 0px !important;
    transition: 0.5s;
}

.dashMain .card-body-primary {
    border-top: 6px solid #673ab7;
    color: #673ab7;
}

.dashMain .card-body-success {
    border-top: 6px solid #15854e;
}

.dashMain .card-body-warning {
    border-top: 6px solid #ffab00;
}

.dashMain .card-body-danger {
    border-top: 6px solid #ef4b4b;
}

.dashMain .card-body:hover::after {
    transform: scaleX(0);
}

.dashMain .card-body:hover::before {
    transform: scaleX(1);
}

.allTxt {
    background-color: #fff !important;
    box-shadow: 0 0 30px rgba(0, 0, 0, .14);
}

.page-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 0;
    position: relative;
}



/* ======================== End Dashboard Box CSS ======================== */


/* ======================== Start DatePicker CSS ======================== */
.daterange {
    background-color: var(--blueLight--) !important;
    color: white !important;
    display: flex;
    margin-bottom: 10px;
    width: 120px !important;
}

.daterange:focus {
    border-color: var(--blueLight--);
    ;
    outline: 0;
    color: white;
    background-color: var(--blueLight--);
    ;
    box-shadow: none;
}

.dateShow span {
    color: var(--blueLight--);
    font-size: 1.25rem !important;
}

.daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 278px;
    max-width: none;
    padding: 0;
    margin-top: 7px;
    top: 100px;
    left: 20px;
    z-index: 3001;
    display: none;
    font-family: arial;
    font-size: 15px;
    line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
    right: 9px;
}

.daterangepicker.opensleft:after {
    right: 10px;
}

.daterangepicker.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
}

.daterangepicker.openscenter:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
}

.daterangepicker.opensright:before {
    left: 9px;
}

.daterangepicker.opensright:after {
    left: 10px;
}

.daterangepicker.drop-up {
    margin-top: -7px;
}

.daterangepicker.drop-up:before {
    top: initial;
    bottom: -7px;
    border-bottom: initial;
    border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
    top: initial;
    bottom: -6px;
    border-bottom: initial;
    border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
    float: none;
}

.daterangepicker.single .drp-selected {
    display: none;
}

.daterangepicker.show-calendar .drp-calendar {
    display: block;
}

.daterangepicker.show-calendar .drp-buttons {
    display: block;
}

.daterangepicker.auto-apply .drp-buttons {
    display: none;
}

.daterangepicker .drp-calendar {
    display: none;
    max-width: 270px;
}

.daterangepicker .drp-calendar.left {
    padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
    padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
    border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    color: #fff;
    border: solid black;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;
}

.daterangepicker .calendar-table .next span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
}

.daterangepicker .calendar-table {
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
}

.daterangepicker .calendar-table table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background-color: #eee;
    border-color: transparent;
    color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
    background-color: #fff;
    border-color: transparent;
    color: #999;
}

.daterangepicker td.in-range {
    background-color: #ebf4f8;
    border-color: transparent;
    color: #000;
    border-radius: 0;
}

.daterangepicker td.start-date {
    border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
    border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
    border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #357ebd;
    border-color: transparent;
    color: #fff;
}

.daterangepicker th.month {
    width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}

.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%;
}

.daterangepicker select.yearselect {
    width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
}

.daterangepicker .calendar-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;
}

.daterangepicker .calendar-time select.disabled {
    color: #ccc;
    cursor: not-allowed;
}

.daterangepicker .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid #ddd;
    display: none;
    line-height: 12px;
    vertical-align: middle;
}

.daterangepicker .drp-selected {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
    border: none;
    margin-left: 8px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
    border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
    border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
    border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
    float: none;
    text-align: left;
    margin: 0;
}

.daterangepicker.show-calendar .ranges {
    margin-top: 8px;
}

.daterangepicker .ranges ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

.daterangepicker .ranges li {
    font-size: 12px;
    padding: 8px 12px;
    cursor: pointer;
}

.daterangepicker .ranges li:hover {
    background-color: #eee;
}

.daterangepicker .ranges li.active {
    background-color: #08c;
    color: #fff;
}


.title h3 {
    color: var(--blueLight--);
    font-size: 20px;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: capitalize;
}

.titlePath {
    margin-bottom: 10px;
}
.reportTable {
    background: #664dc9;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
} 

.reportBorder tr th:first-child {
    border-radius: 22px 0 0 0;
    overflow-x: hidden;
}

.reportBorder tr th:last-child {
    border-radius: 0 22px 0 0;
    overflow-x: hidden;
}



/*  Larger Screen Styling */
@media (min-width: 564px) {
    .daterangepicker {
        width: auto;
    }

    .daterangepicker .ranges ul {
        width: 140px;
    }

    .daterangepicker.single .ranges ul {
        width: 100%;
    }

    .daterangepicker.single .drp-calendar.left {
        clear: none;
    }

    .daterangepicker.single .ranges,
    .daterangepicker.single .drp-calendar {
        float: left;
    }

    .daterangepicker {
        direction: ltr;
        text-align: left;
    }

    .daterangepicker .drp-calendar.left {
        clear: left;
        margin-right: 0;
    }

    .daterangepicker .drp-calendar.left .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .daterangepicker .drp-calendar.right {
        margin-left: 0;
    }

    .daterangepicker .drp-calendar.right .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .daterangepicker .drp-calendar.left .calendar-table {
        padding-right: 8px;
    }

    .daterangepicker .ranges,
    .daterangepicker .drp-calendar {
        float: left;
    }
}

@media (min-width: 730px) {
    .daterangepicker .ranges {
        width: auto;
    }

    .daterangepicker .ranges {
        float: left;
    }

    .daterangepicker.rtl .ranges {
        float: right;
    }

    .daterangepicker .drp-calendar.left {
        clear: none !important;
    }
}


/* ======================== End DatePicker CSS ======================== */

/* Title CSS */

.title h3 {
    color: var(--blueLight--);
    font-size: 20px;
    font-weight: 700;
    line-height: 2.5rem;
}

.titlePath {
    margin-bottom: 10px;
}


/* ================ setting ======================= */

.displayCountry {
    max-height: 100px;
    min-height: 50px;
    overflow: auto;
}

.displayCountry span {
    background-color: #60529f;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

tbody {
    background-color: #ffff !important;
}

.btn-pill {
    border-radius: 10rem;
}

/* ======== profile css ==================== */

.widget-user-image {
    border-radius: 100%;
    box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
    -moz-box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
    -webkit-box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
    -ms-box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
    clear: both;
    display: block;
    height: 100px;
    margin: 0 auto;
    position: relative;
    width: 100px;
}

.widget-user-image img {
    border: 3px solid #f5f6f8 !important;
    box-shadow: 0 0 0 4px #eeeef3 !important;
}

.widget-user-image1 .avatar {
    height: 9rem;
    width: 9rem;
}

.widget-user .wideget-user-icons a {
    border-radius: 100px;
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    margin-top: 0.3rem;
    text-align: center;
    width: 2rem;
}

/*==================== gift css ============================  */
.gift_category__title {
    color: #000000de;
    font-size: 22px;
    font-weight: 650 !important;
}

.dialog__input__title {
    color: #000;
    font-size: 20px;
    font-weight: 700 !important;
}

.h4 {
    font-size: 1.25rem;
}

.gift-card {
    border: none;
    margin: 10px;
    border-radius: 12px;
}

.categoryImage {
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
    width: 100px;
}


/* error 404 page css start */
.page,
.page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.page-content {
    margin: 0.75rem 0;
    min-height: 100%;
}

.error-text {
    text-shadow: 7px 3px 3px rgba(0, 0, 0, .3);
}

.display-1 {
    font-size: 9.5rem;
    font-weight: 500;
    line-height: 1.1;
}

.leading-normal {
    line-height: 1.5 !important;
}


.form-control:focus {
    box-shadow: 0 0 0 0rem #ffff !important;
}


/* error 404 css end */


